import React from "react"
import { Link } from "react-router-dom"

import "./styles.scss"

import logo_black from "../../assets/drawable-xxhdpi/footer_logo_black.png"
import logo_gray from "../../assets/drawable-xxhdpi/footer_logo_gray.png"
import logo_white from "../../assets/drawable-xxhdpi/footer_logo_white.png"
import instagram from "../../assets/drawable-xxhdpi/footer_instagram_white.png"
import blog from "../../assets/drawable-xxhdpi/footer_blog_white.png"
import twiter from "../../assets/drawable-xxhdpi/footer_twiter_white.png"
import facebook from "../../assets/drawable-xxhdpi/footer_facebook_white.png"
import youtube from "../../assets/drawable-xxhdpi/footer_youtube_white.png"

export default function Footer(props) {
    return (
        <div className={props.location && props.location.pathname === "/wte" ? "footer-wrap black" : "footer-wrap"}>
            <div className="in-wrap">
                <div className="left-wrap">
                    <div className="top">
                        <Link to="">
                            <img src={props.location && props.location.pathname === "/wte" ? logo_white : logo_black} alt="" />
                        </Link>
                    </div>
                    <div className="middle">
                        <div>
                            <ul>
                                {/* <li><Link to="/"><span>홈</span></Link></li> */}
                                {/* <li><Link to="/about"><span>회사소개</span></Link></li> */}
                                {/* <li>
                                    <Link to="/wte">
                                        <span>Watch to Earn</span>
                                    </Link>
                                </li>
                                <li>
                                    <a href="https://fancplace.io" target="_blank" rel="noreferrer">
                                        <span>fanCPlace</span>
                                    </a>
                                </li>
                                <li>
                                    <Link to="/faq">
                                        <span>FAQ</span>
                                    </Link>
                                </li> */}
                                <li>
                                    <Link to="/notice">
                                        <span>공지사항</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        {/* <div>
                            <ul>
                                <li><Link to="/faq"><span>FAQ</span></Link></li>
                                <li><Link to="/notice"><span>공지사항</span></Link></li>
                                <li><Link to="/recruit"><span>채용공고</span></Link></li>
                                <li><Link to=""><span>서비스이용약관</span></Link></li>
                                <li><Link to=""><span>개인정보 처리방침</span></Link></li>
                                <li><Link to=""><span>커뮤니티 가이드라인</span></Link></li>
                            </ul>
                        </div> */}
                    </div>
                    <div className="bottom">
                        <p>
                            사업자등록번호: 818-86-01364<span> | </span>통신판매업 신고번호: 제 2019-서울강남-04411호
                        </p>
                        <p>
                            주소: 서울특별시 강남구 테헤란로20길 10, 10층 (역삼동, 쓰리엠타워)<span> | </span>이메일 : cs@celebe.io<span> | </span>
                            대표이사: 권혁문
                        </p>
                        <p>
                            CELEBE : cs@celebe.io<span> | </span>fanC : contact@fanc.io
                        </p>
                        <p>Copyright &copy; 2022 CELEBe Inc.</p>
                    </div>
                </div>
                <div className="right-wrap">
                    <a href="https://www.youtube.com/c/CELEBe" target="_blank" rel="noreferrer">
                        <img src={youtube} alt="" />
                    </a>
                    <a href="https://blog.naver.com/celebekorea" target="_blank" rel="noreferrer">
                        <img src={blog} alt="" />
                    </a>
                    <a href="https://instagram.com/celebe_official" target="_blank" rel="noreferrer">
                        <img src={instagram} alt="" />
                    </a>
                    <a href="https://twitter.com/celebe_official?s=20&t=1Y_mXoXvb9w6O20SD7T73Q" target="_blank" rel="noreferrer">
                        <img src={twiter} alt="" />
                    </a>
                    {/* <a href="https://www.facebook.com/Celebeofficial" target="_blank" rel="noreferrer" ><img src={facebook} alt="" /></a> */}
                </div>
            </div>
        </div>
    )
}
