import React, { useState } from "react"
import { Link, useNavigate } from "react-router-dom"

import logo_b from "../../assets/drawable-xxhdpi/footer_logo_black.png"
import logo_p from "../../assets/drawable-xxhdpi/top_logo_purple.png"
import logo_w from "../../assets/drawable-xxhdpi/footer_logo_white.png"
import more_b from "../../assets/drawable-xxhdpi/ic_more_black.png"
import more_w from "../../assets/drawable-xxhdpi/ic_more_white.png"
import close from "../../assets/drawable-xxhdpi/ic_close_24.png"
import "./styles.scss"

export default function Top(props) {
    const [isOpen, setIsOpen] = useState(false)
    let navigate = useNavigate()
    return (
        <div className={props.location && props.location.pathname === "/wte" ? "top-wrap black" : "top-wrap"}>
            <div className="in-wrap">
                <div className="left-wrap">
                    <Link to="/">
                        <img src={props.location && props.location.pathname === "/wte" ? logo_w : logo_b} alt="" />
                    </Link>
                </div>
                <div className="right-wrap">
                    <button onClick={() => setIsOpen(true)}>
                        <img src={props.location && props.location.pathname === "/wte" ? more_w : more_b} alt="" />
                    </button>
                    <ul>
                        {/* <li className={props.location && props.location.pathname === '/about' ? "active" : ""}><Link to="/about"><span>회사소개</span></Link></li> */}
                        {/* <li className={props.location && props.location.pathname === "/wte" ? "active" : ""}>
                            <Link to="/wte">
                                <span>Watch to Earn</span>
                            </Link>
                        </li>
                        <li>
                            <a href="https://fancplace.io" target="_blank" rel="noreferrer">
                                <span>fanCPlace</span>
                            </a>
                        </li>
                        <li className={props.location && props.location.pathname === "/faq" ? "active" : ""}>
                            <Link to="/faq">
                                <span>FAQ</span>
                            </Link>
                        </li> */}
                        <li className={props.location && props.location.pathname === "/notice" ? "active" : ""}>
                            <Link to="/notice">
                                <span>공지사항</span>
                            </Link>
                        </li>
                        {/*<li><Link to="/recruit"><span>채용공고</span></Link></li> */}
                    </ul>
                </div>
            </div>

            {isOpen && (
                <div className="mobile-menu">
                    <div className="inner">
                        <div className="top">
                            <a className="icon" onClick={() => setIsOpen(false)}>
                                <img src={close} />
                            </a>
                        </div>
                        <div className="bottom">
                            <ul>
                                {/* <li><a onClick={() => {
                                    navigate("/about")
                                    setIsOpen(false)
                                }}><span>회사소개</span></a></li> */}
                                {/* <li>
                                    <a
                                        onClick={() => {
                                            navigate("/wte")
                                            setIsOpen(false)
                                        }}
                                    >
                                        <span>Watch to Earn</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://fancplace.io" target="_blank" rel="noreferrer" onClick={() => setIsOpen(false)}>
                                        <span>fanCPlace</span>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        onClick={() => {
                                            navigate("/faq")
                                            setIsOpen(false)
                                        }}
                                    >
                                        <span>FAQ</span>
                                    </a>
                                </li> */}
                                <li>
                                    <a
                                        onClick={() => {
                                            navigate("/notice")
                                            setIsOpen(false)
                                        }}
                                    >
                                        <span>공지사항</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
